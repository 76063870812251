$background: #f5f7ff;

$blue: #1ea5fc;
$blue-darker: #0077ec;
$blue-lighter: #4fa8ff;
$blue-super-light: #e8f0fe;

$red: #ff4757;
$red-lighter: #ff4757;
$red-darker: #ff4757;
$red-background: #fff0f1;

$lilac: #a990dd;
$lilac-background: #f6f1ff;

$green: #2ed573;
$green-lighter: #77ea7e;
$green-darker: #25a95c;
$green-background: #f4fff9;

$orange: #fd820b;
$orange-background: #fff2dc;

$white: #fff;
$black: #333;
$gray: #525668;
$light-gray: #cacaca;

//TYPOGRAPHY
$font-size-small: 12px;
$font-size-default: 14px;
$font-size-big: 18px;
$font-size-title: 32px;

//LAYOUT
$border-radius: 12px;
$border-radius-big: 24px;
