@import "variables";
@import "mixins";

body {
  font-family: "Lato", sans-serif;
  font-weight: 400;
  line-height: 1.6;
}

button,
input,
textarea,
select {
  font-family: inherit;
  font-weight: inherit;
  font-size: $font-size-default;
  color: $black;
  line-height: 1.6;
}

button,
input,
textarea,
select {
  font-family: inherit;
  font-weight: inherit;
  font-size: $font-size-default;
  color: $black;
}

h1,
h2,
h3,
h4 {
  color: $black;
  font-weight: 400;
}

h1 {
  @include stop_selection;
  text-align: center;
  font-size: $font-size-title;
}

h2 {
  font-size: $font-size-big;
  text-align: center;
}

h3 {
  font-size: $font-size-default;
  text-align: center;
}

h4 {
  font-size: $font-size-small;
  text-align: center;
}

b {
  font-weight: 600;
}

a {
  text-decoration: none;
}
