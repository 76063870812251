@import "variables";

@mixin flex($direction: "") {
  display: flex;
  align-items: center;
  @if ($direction == center) {
    justify-content: center;
  } @else if ($direction == space-between) {
    justify-content: space-between;
  } @else if ($direction == space-around) {
    justify-content: space-around;
  } @else if ($direction == start) {
    justify-content: flex-start;
  } @else if ($direction == end) {
    justify-content: flex-end;
  }
}

@mixin absolute_center($X: 0%, $Y: 0%) {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(calc(-50% + #{$X}), (calc(-50% + #{$Y})));
}

@mixin animation {
  transition: all 0.1s;
}

@mixin clickable($basic: false) {
  @include animation;
  cursor: pointer;

  @if ($basic == false) {
    &:hover {
      box-shadow: 0 0 10px rgba($black, 0.2);
    }
    &:active {
      box-shadow: none;
    }
  }
}

@mixin stop_selection {
  user-select: none;
  -webkit-user-drag: none;
}
