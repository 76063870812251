html {
  box-sizing: border-box;
}

* {
  margin: 0;
  padding: 0;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  background-color: $background;
}

button,
input,
optgroup,
select,
textarea,
html input[type="button"],
input[type="reset"],
input[type="submit"],
button[disabled],
html input[disabled],
input[type="checkbox"],
input[type="radio"],
input[type="search"] {
  outline: none;
  border: none;
  background-image: none;
  background-color: transparent;
  box-shadow: none;
}
